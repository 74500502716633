<template>
    <main>
        <input v-model="val"  type="text" class="form-control">
    </main>
</template>
<script>
export default {
    props: ['antecedente','value'],
    data () {
        return{
            val:this.value,
        }
    },
    watch:{
        val:function(){
            this.$emit('input',this.val);
        }
    }
}
</script>


