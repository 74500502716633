import Service from "./Service";
const baseurl = '/api/antecedentes-usuario';
export default {
    index(id, id_grupo = '', params = {}) {
        const url = id_grupo === '' ? `${baseurl}/${id}` : `${baseurl}/${id}/${id_grupo}`;
        return Service.get(url, {
            params: params,
        });

    },
    store(obj) {
        return Service.post(`${baseurl}/store`, obj);
    },


}
