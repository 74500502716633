<template>
  <main>
    <modalInformacionUsuario :usuario="usuario"></modalInformacionUsuario>
    <page-header-compact></page-header-compact>
    <div class="container-xl px-4 mt-n15">
      <div class="card ">
        <cardInformacionUsuario :usuario="usuario"></cardInformacionUsuario>
      </div>
      <div class="card mt-2">
        <div class="card-header">
          <ul class="nav nav-tabs card-header-tabs">
            <li v-for="(grupo, i) in grupos" :key="grupo.id" class="nav-item">
              <a
                class="nav-link "
                :class="{ active: i === 0 }"
                :id="`nav_${grupo.id}`"
                data-toggle="tab"
                :href="`#tab_${grupo.id}`"
                role="tab"
                aria-controls="nav-home"
                aria-selected="true"
                >{{ grupo.nombre }}</a
              >
            </li>
          </ul>
        </div>

        <div class="card-body">
          <div class="tab-content" id="nav-tabContent">
            <div
              v-for="(grupo, i) in grupos"
              :key="grupo.id"
              class="tab-pane fade pt-2"
              :class="{ 'show active': i === 0 }"
              :id="`tab_${grupo.id}`"
              role="tabpanel"
              aria-labelledby="nav-home-tab"
            >
              <div class="row">
                <div
                  class="col-lg-4 col-md-6 form-group "
                  v-for="antecedente in grupo.antecedentes"
                  :key="antecedente.id"
                >
                  <label class="form-label">{{
                    antecedente.descripcion
                  }}</label>
                  <texto
                    v-if="antecedente.tipo_antecedente.codigo === 'TEXT'"
                    v-model="antecedente.antecedente_usuario.valor"
                  ></texto>
                  <numerico
                    v-if="antecedente.tipo_antecedente.codigo === 'NUM'"
                    v-model="antecedente.antecedente_usuario.valor"
                  ></numerico>
                  <opciones
                    :antecedente="antecedente"
                    v-if="antecedente.tipo_antecedente.codigo === 'OPC'"
                    v-model="antecedente.antecedente_usuario.valor"
                  ></opciones>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-2">
          <div class="col-12">
              <button class="btn btn-success" @click="guardar()">
                  <i class="fas fa-save fa-fw text-white-50"></i>
                  Guardar
              </button>
          </div>
      </div>
    </div>
  </main>
</template>
<script>
import cardInformacionUsuario from "../base/informacionUsuario/cardInformacionUsuario";
import usuarioService from "../../../services/usuarioService";
import PageHeaderCompact from "../../../components/layouts/content/page-header-compact";
import modalInformacionUsuario from "../base/informacionUsuario/modalInformacionUsuario";
import texto from "./tipos/texto";
import numerico from "./tipos/numerico";
import opciones from "./tipos/opciones";
import grupoAntecedenteService from "../../../services/grupoAntecedenteService";
import Swal from "sweetalert2";
import antecedenteUsuarioService from "../../../services/antecedenteUsuarioService";

export default {
  components: {
    cardInformacionUsuario,
    modalInformacionUsuario,
    PageHeaderCompact,
    texto,
    numerico,
    opciones,
  },
  data() {
    return {
      usuario: {},
      grupos: [],
    };
  },
  methods: {
    async cargarUsuario(id) {
      const response = await usuarioService.show(id);
      this.usuario = response.data;
    },
    async guardar() {
      let antecedentes_usuario = [];
      this.grupos.forEach((x) => {
        x.antecedentes.forEach((y) => {
          if (y.antecedente_usuario.valor !== "") {
            antecedentes_usuario.push({ ...y.antecedente_usuario });
          }
        });
      });
      this.LoaderSpinnerShow();
      await antecedenteUsuarioService.store({
        antecedentes_usuario: antecedentes_usuario,
      });
      this.LoaderSpinnerHide();
      Swal.fire("Datos Guardados con exito", "", "success");
    },
  },
  async created() {

    this.LoaderSpinnerShow();

    await this.cargarUsuario(this.$route.params.id);

    const filter = {
      sexo: this.usuario.sexo,
      fecha_nacimiento : this.usuario.fecha_nacimiento
    };

    const response = await grupoAntecedenteService.index(filter);

    const antecedentes_usuario = await antecedenteUsuarioService.index(
      this.usuario.id
    );

    this.grupos = response.data
      .filter((x) => x.antecedentes.length > 0)
      .map((x) => {
        x.antecedentes.forEach((y, i) => {
          const indice = antecedentes_usuario.data.findIndex(
            (a) => a.id_antecedente === y.id
          );
          x.antecedentes[i]["antecedente_usuario"] = {
            id: indice !== -1 ? antecedentes_usuario.data[indice].id : 0,
            id_usuario: this.usuario.id,
            id_antecedente: y.id,
            valor: indice !== -1 ? antecedentes_usuario.data[indice].valor : "",
          };
        });
        return {
          ...x,
        };
      });

      this.LoaderSpinnerHide();
  },
};
</script>
